import { Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BookingProvider } from "./state/booking-context";
import About from "./views/about";
import Layout from "./components/common/layout";
import BookingService from "./views/booking-service";
import BookingStaff from "./views/booking-staff";
import BookingDate from "./views/booking-date";
import BookingCustomer from "./views/booking-customer";
import PrivateRoute from "./components/private-route";
import BookingConfirm from "./views/booking-confirm";
import NotFound from "./views/not-found";
import React from "react";
import {Helmet} from "react-helmet";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#329AD0"
    }
  }
});

export default class App extends React.Component {
  render() {
      document.title = 'wow'
      return <div className="App">
      <Helmet>
        <meta name="apple-itunes-app" content="app-id=1496982047"/>  
        <meta name="google-play-app" content="app-id=com.aznsoft.uzmos"/>
        <link rel="apple-touch-icon" href="icon.png"/>
        <link rel="android-touch-icon" href="icon.png"/>
      </Helmet>
      <Switch>
        <MuiThemeProvider theme={theme}>
          <Route path="/not-found" exact component={NotFound} />
          <BookingProvider>
            <Layout>
              <Route path={["/", "/:staff", "/booking/service"]} exact component={BookingService} />
              <Route path="/booking/staff" exact component={BookingStaff} />
              <PrivateRoute bookingType="date" path="/booking/date" exact component={BookingDate} />
              <PrivateRoute bookingType="customer" path="/booking/customer" exact component={BookingCustomer} />
              <Route path="/company/about" exact component={About} />
              <PrivateRoute bookingType="confirm" path="/booking/confirm" exact component={BookingConfirm} />
            </Layout>
          </BookingProvider>
        </MuiThemeProvider>
      </Switch>
    </div>
  }
}
