import { Dialog, makeStyles } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingDialog() {
    const classes = useStyles();
    return <Dialog
        open={true}
        classes={classes}
        style={{width: '200px', marginLeft: '40%', backgroundColor: 'transparent'}}
        title= 'Loading'>
        <CircularProgress  style= {{display: 'inline-block'}} size={50}  
        />
    </Dialog>
}
const useStyles = makeStyles({
    paper: {
        overflow:'hidden',
        minHeight: '100px',
        minWidth: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'none'
    },
    root: {
        backgroundColor: 'transparent'
    }
  });