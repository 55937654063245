import { Container, Box, Grid, Drawer, useMediaQuery, makeStyles, IconButton, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Menu as MenuIcon, Close } from "@material-ui/icons";
import { useState } from "react";
import MomentUtils from '@date-io/moment';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Location from "./general-info";
import Menu from "./menu";
import { useBookingContext } from '../../state/booking-context';
const drawerWidth = 240;
interface LayoutProps {
    children: JSX.Element[] | JSX.Element;
}

function Layout({ children }: LayoutProps) {
    const classes = useStyles()
    // const [openPromptDownload, setOpenPromptDownload] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const tabletView = useMediaQuery('(max-width:1280px)');
    const { store} = useBookingContext();
    // useEffect(() => {
    //     setTimeout(handleOpenPrompt, 2000);
    // }, [])

    // function handleClosePrompt() {
    //     setOpenPromptDownload(false);
    // }


    // function handleOpenPrompt() {
    //     setOpenPromptDownload(true);
    // }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            {
                !tabletView ? <Box></Box> : (
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={() => setShowSidebar(!showSidebar)}
                            >
                            {
                                 showSidebar ? <Close /> : <MenuIcon />
                             }
                            </IconButton>
                            <Typography variant="body2" color="inherit" noWrap>
                                {store?.name}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    // <Container>
                    //     <IconButton className={classes.menuButton} onClick={() => setShowSidebar(!showSidebar)}>
                    //         {
                    //             showSidebar ? <Close /> : <MenuIcon />
                    //         }
                    //     </IconButton>
                    //     <Typography variant="body2" color="inherit" noWrap>
                    //         Responsive drawer
                    //         </Typography>
                    // </Container>

                    
                )
            }
            <Box width="100vw" height="100vh" display="flex" justifyContent="center" paddingTop={!tabletView ? 20 : 10} >
                {/* <DownloadAppPrompt open={openPromptDownload} handleClosePrompt={handleClosePrompt} /> */}
                <Container>
                    <Grid container>
                        {
                            !tabletView ? (
                                <Grid item lg={3}>
                                    <Menu />
                                </Grid>
                            ) : (
                                <Drawer open={showSidebar}>
                                    <IconButton className={classes.menuButton} onClick={() => setShowSidebar(!showSidebar)}>
                                        <Close /> 
                                    </IconButton>
                                    <Menu />
                                </Drawer>
                            )
                        }
                        <Grid item md={12} sm={12} xs={12} lg={6}>
                            {children}
                        </Grid>
                        {
                            !tabletView && (
                                <Grid item md={12} sm={12} xs={12} lg={3}>
                                    <Location/>
                                </Grid>
                            )
                        }
                        
                    </Grid>
                </Container>
            </Box>
        </MuiPickersUtilsProvider>


    )
}

const useStyles = makeStyles(() => ({
    appBar: {
        position: 'absolute',
        marginLeft: drawerWidth,
        width: '100%',
    },
    navIconHide: {
        display: 'none',
    },
    menuButton: {
        position: "fixed",
        top: 1,
        left: 1,
        zIndex: 99
    }
}));

export default Layout;
