export interface Customer {
    id?: number;
    phoneNumber: string;
    countryCode: string;
    email: string;
    firstName: string;
    lastName: string;
}

export const DEFAULT_CUSTOMER: Customer = {
    id: undefined,
    phoneNumber: "",
    countryCode: "",
    email: "",
    firstName: "",
    lastName: ""
}