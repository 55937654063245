import phone from "phone";

export function validateEmail(email: string | undefined): boolean {
    if (!email) return false;
    const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regexEmail.test(email);
}

export function validatePhoneNumber(phoneNumber: string | undefined): boolean {
    if (!phoneNumber) return false;
    const [number, country] = phone(phoneNumber);
    if (!number || !country) return false;
    return true;
}

