import Axios from "axios";

import { AppConfig } from "../app.config";
import { BookingAppointment } from "../models/booking";
import { Customer } from "../models/customer";
import { Service, Staff } from "../models/staff";
import { Store } from "../models/store";

const axios = Axios.create({
    baseURL: AppConfig.apiURL,
    headers: { Authorization: `Bearer ${AppConfig.clientSecret}` }
});

export async function getStaff(subDomain: string, staffName: string) {
    const { data } = await axios.get<Staff>("/appointment/booking-web/staff", {
        params: {
            staffName,
            subDomain
        },
    });

    return data;
}

export async function getStore(subDomain: string) {
    const { data } = await axios.get<Store>("appointment/booking-web/store", {
        params: {
            subDomain
        },
        headers: { Authorization: `Bearer ${AppConfig.clientSecret}` }
    });

    return data;
}

export async function bookNewAppointment(bookingAppointment: BookingAppointment) {
    const { data } = await axios.post<BookingAppointment>("/appointment/booking-web/", bookingAppointment);

    return data;
}

export async function getCustomerByPhoneNumber(phoneNumber: string) {
    const { data } = await axios.get<Customer>("/appointment/booking-web/customer", {
        params: { phoneNumber },
    });

    return data;
}

export async function getBookingSlots(staffId: number, timezone: string, date: string) {
    const { data } = await axios.get("/appointment/booking/slots/", {
        params: {
            staffId,
            timezone,
            date
        }
    });

    return data;
}
export async function getServices(subDomain: string, staffName?:string): Promise<Service[]> {
    const { data } = await axios.get("/appointment/booking-web/store/services", {
        params: {
            subDomain,
            staffName
        }
    });

    return data;
}
export async function getStaffs(subDomain: string) {
    const { data } = await axios.get("/appointment/booking-web/store/staffs", {
        params: {
            subDomain
        }
    });

    return data;
}