import { FormControl } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
interface Props {
    phoneNumber: string | undefined;
    phoneNumberErr: string | undefined;
    handlePhoneChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => void;
}

function BookingPhoneNumber({ phoneNumber, phoneNumberErr, handlePhoneChange }: Props) {
    return (
        <FormControl fullWidth >
            <MuiPhoneNumber
                value={phoneNumber}
                onlyCountries={["us"]}
                countryCodeEditable={false}
                helperText={phoneNumberErr}
                error={!!phoneNumberErr}
                onChange={(e) => handlePhoneChange(e)}
                label="Phone Number" size="medium" variant="outlined" defaultCountry="us" />
        </FormControl>
    )
}
export default BookingPhoneNumber;
