import { Box, Typography, useMediaQuery } from '@material-ui/core'
import { getMapSnapShot } from "../utils/map-snapshot";
import { useBookingContext } from "../state/booking-context";
function About() {
    const tabletView = useMediaQuery('(max-width:1300px)');
    const { store } = useBookingContext();
    if (!store) return <div></div>;
    
    const storeAddress = `${store?.address}, ${store?.city}, ${store?.state} ${store?.zipcode}`;

    return (
        <Box padding={!tabletView ? 3 : 1} >
            <Box textAlign={!tabletView ? "start" : "center"}>
                <Typography variant="h6">About Us</Typography>
            </Box>
            {
                !tabletView && (
                    <Box marginY={2} >
                        <img src={getMapSnapShot(store?.latitude, store?.longitude)} width="562.109" height="300" alt="location" />
                    </Box>
                )
            }
            <Box textAlign={!tabletView ? "end" : "center"} mt={3} >
                <Typography variant="h6">{store?.name}</Typography>
                <Box my={1}></Box>
                <Typography>{store?.email}</Typography>
                <Typography>{store?.phoneNumber}</Typography>
                <Typography>{storeAddress}</Typography>

            </Box>


        </Box>
    )
}

export default About;