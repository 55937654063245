import createUseContext from "constate";
import { Moment } from "moment";
import { useState } from "react";
import { Customer } from "../models/customer";
import { Staff, Service } from "../models/staff";
import { Store } from "../models/store";

export const context = createUseContext(() => {
    const [staff, setStaff] = useState<Staff>();
    const [bookingDate, setBookingDate] = useState<Moment | null>(null);
    const [bookingCustomer, setBookingCustomer] = useState<Customer>();
    const [note, setNote] = useState<string | undefined>();
    const [staffName, setStaffName] = useState<string | undefined>();
    const [subdomain, setSubdomain] = useState<string | undefined>();
    const [bookingService, setBookingService] = useState<Service>();
    const [store, setStore] = useState<Store>();
    const [appointmentBooked, setAppointmentBooked] = useState<boolean>(false);


    return { 
        staff, 
        setStaff, 
        store, 
        setStore, 
        bookingDate, 
        setBookingDate, 
        bookingCustomer, 
        setBookingCustomer, 
        note, 
        setNote, 
        bookingService, 
        setBookingService, 
        appointmentBooked, 
        setAppointmentBooked,
        staffName, 
        setStaffName,
        subdomain, 
        setSubdomain
    };
});

export const [BookingProvider, useBookingContext] = context;