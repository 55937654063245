import { Box, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Logo from "../images/uzmos_light.png";

function useQuery() {
    return queryString.parse(useLocation().search);
}

export default function NotFound() {
    const { message, status } = useQuery();

    return (
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="100vw" height="100vh">
            <img src={Logo} alt="logo"/>
            <Box py={1}></Box>
            <Typography variant="h6" color="primary">Not Found | {status} </Typography>
            <Box py={1}></Box>
            <Typography variant="body2" color="textSecondary">{message}</Typography>
        </Box>
    )
}
