import { Box, Button, createMuiTheme, Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';
import {ThemeProvider} from "@material-ui/styles";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useHistory } from 'react-router';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LoadingDialog from "../components/common/loading-dialog";
import { BookingSlot } from "../models/booking";
import { useBookingContext } from '../state/booking-context';
import { getBookingSlots } from "../api/appointment";
import Booking from "../components/booking/booking";
import moment, { Moment } from 'moment';
import { useCallback } from 'react'


function BookingDate() {
    const mobileView = useMediaQuery('(max-width:600px)');
    const { staff, store, bookingDate, setBookingDate, staffName, bookingService } = useBookingContext();
    const [date, setDate] = useState<Moment | null>(moment());
    const [availbleTimes, setAvailableTimes] = useState<Moment[]>();
    const [hasEvening, setHasEvening] =  useState<boolean>(false);
    const history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] =  useState<boolean>(false);
    const isInNextGap = useCallback((timeSlot: Date, bookingSlots: BookingSlot[]) => {
        const duration = bookingService?.serviceDuration || 0;
        const endTimeOfSerivce = moment(timeSlot).add(duration, "minutes");
        const index = bookingSlots.findIndex(s => endTimeOfSerivce.isSameOrBefore(s.time));
        if(index > 0) {
            return !bookingSlots[index-1].open;
        } 
        return true;
    },[ bookingService])


    const getAvailableTime = useCallback(async (staffId: number, storeTimeZone : string, formattedDate: string) => {
        setLoading(true);
        const bookingSlots: BookingSlot[] = await getBookingSlots(staffId, storeTimeZone, formattedDate);
        const timeSlots = bookingSlots.filter((slot: BookingSlot) => slot.open === true && !isInNextGap(slot.time, bookingSlots));
        const availbleTimes: Moment[] = timeSlots.map((slot: BookingSlot) => moment(slot.time));
        setHasEvening(availbleTimes.some(time=> getSplitTime(time) === 'evening'))
        setAvailableTimes(availbleTimes);
        setLoading(false);
    }, [isInNextGap]);

    const handleDateChange = useCallback(async (selectedDate: Moment | null) => {
        setAvailableTimes(undefined);
        setDate(selectedDate);
        const formattedDate = selectedDate?.format("MM/DD/YYYY");
        if (staff && store && formattedDate) {
            await getAvailableTime(staff.id, moment.tz.guess(), formattedDate);
        }
    }, [staff, store, getAvailableTime])

    useEffect( () => {
        if (bookingDate) {
            setDate(bookingDate);
            handleDateChange(bookingDate);
        } else {
            if(!bookingService) {
                history.push("/booking/service");
            } else if (staff && store) {
                getAvailableTime(staff.id, moment.tz.guess(), moment().format("MM/DD/YYYY"));
            }
        }
    }, [bookingDate, handleDateChange, staff, store, getAvailableTime, bookingService, history]);



    function handleSelectTimeSlot(timeSlot: Moment) {
        // setSelectedTimeSlot(timeSlot);
        setBookingDate(timeSlot);
        history.push("/booking/customer");
    }

    function getSplitTime (m: Moment) {
        var g = null; //return g
        if(!m || !m.isValid()) { return; } 
        
        var split_afternoon = 12;
        var split_evening = 17;
        var currentHour = parseFloat(m.format("HH"));
        
        if(currentHour >= split_afternoon && currentHour <= split_evening) {
            g = "afternoon";
        } else if(currentHour >= split_evening) {
            g = "evening";
        } else {
            g = "morning";
        }
        return g;
    }

    function timeSlot(time:Moment) {
        return (
            <Grid item style={{ marginRight: "0" }} >
                <Box style={{ width: "100%" }} display="flex" justifyContent="center" alignContent="center">
                    {
                        <Button key={time.locale()} onClick={() => handleSelectTimeSlot(time)} style={{ width: "100px", }}
                            color="primary" size="small" variant="outlined">
                            {time.format("hh:mm a")}
                        </Button>
                    }
                </Box>
            </Grid>
        )
    }

    function allBooked() {
        return (
            <Grid item style={{ marginRight: "0" }} >
                <Box style={{ width: "100%" }} display="flex" justifyContent="center">
                    {
                        <Button key="allBooked" color="secondary" size="small" variant="outlined">
                            All Booked
                        </Button>
                    }
                </Box>
            </Grid>
        )
    }

    function filterSlotBy(timeOfDay: string) {
        return availbleTimes ? availbleTimes.filter(time=> getSplitTime(time) === timeOfDay) : []
    }

    function slotOrEmpty(timeOfDay: string) {
        const slots = filterSlotBy(timeOfDay);
        if(slots.length > 0) {
            return slots.map(time => timeSlot(time))
        } else {
            return allBooked()
        }
    }

    function goBack() {
        if(!staffName)
            history.push("/booking/staff");
        else 
            history.push("/booking/service");
    }

    return (
        <Booking>
            {loading && <LoadingDialog />}
            
            <Box display="flex" justifyContent="center" padding="0 15px" marginBottom="20px">
            { mobileView &&
                <ArrowBackIosIcon color="primary" className={classes.arrow} onClick={() => goBack()} />
            }
                <Typography style={{ fontSize: 15, fontWeight:600 }}>Choose Date</Typography>
            </Box>
            <Box display="flex" flexDirection={mobileView ? 'column' : 'row'} justifyContent="center">
                <Box flexGrow mb={!mobileView ? 3 : 1}>
                {!mobileView ? 
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ThemeProvider theme={materialTheme}>
                        <DatePicker
                            disablePast
                            disableToolbar
                            autoOk
                            orientation={!mobileView ? "landscape" : "portrait"}
                            variant="static"
                            openTo="date"
                            value={date}
                            onChange={(date) => handleDateChange(date)}
                        />
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
                : <DatePicker
                disablePast
                disableToolbar
                autoOk
                orientation={!mobileView ? "landscape" : "portrait"}
                variant="static"
                openTo="date"
                value={date}
                onChange={(date) => handleDateChange(date)} />
                }
                    
                </Box>
                <Box width="100%">
                    <Box textAlign="center" my={2}>
                        {
                            bookingDate ? (
                            <Typography color="secondary">
                                Selected Appointment Date: {bookingDate?.format("dddd, MMMM Do YYYY, h:mm a")}
                            </Typography>
                            ) : (
                            <Typography>
                                Selected a time for: <Box component="span" fontWeight='600'>{date?.format("dddd, MMMM Do")}</Box>
                            </Typography>
                            )
                        }
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={hasEvening ? 4 : 6} sm={hasEvening ? 4 : 6}>
                            <Box display="flex" justifyContent="center" >
                                <Typography style={{ fontSize: 12, fontWeight: 500 }}>MORNING</Typography>
                            </Box>
                            <Grid container item spacing={1} justify="center">
                            {
                                availbleTimes && (
                                    slotOrEmpty('morning')
                                )
                            }
                            </Grid>
                        </Grid>
                        <Grid item xs={hasEvening ? 4 : 6} sm={hasEvening ? 4 : 6}>
                            <Box display="flex" justifyContent="center">
                                <Typography style={{ fontSize: 12, fontWeight: 500 }}>AFTERNOON</Typography>
                            </Box>
                            <Grid container item spacing={1} justify="center">
                            {
                                availbleTimes && (
                                    slotOrEmpty('afternoon')
                                )
                            }
                            </Grid>
                        </Grid>
                        {
                            hasEvening && (
                                <Grid item xs={4} sm={4}>
                            <Box display="flex" justifyContent="center">
                                <Typography style={{ fontSize: 12, fontWeight: 500 }}>EVENING</Typography>
                            </Box>
                            <Grid container item spacing={1} justify="center">
                            {
                                availbleTimes && (
                                    slotOrEmpty('evening')
                                )
                            }
                            </Grid>
                        </Grid>
                            )
                        }
                    </Grid>
                </Box>
                
            </Box>

        </Booking >

    )
}

const useStyles = makeStyles(theme => ({
    arrow: {
        float: "left",
        position: "absolute",
        left: "20px",
        lineHeight: "22px",
        fontSize: "22px",
        marginRight: "10px",
        cursor: "pointer"
    },
    notInThisMonthDayPaper: {
        width: "35px",
        height: "35px",
        backgroundColor: "#eeeeee",
        margin: "3px",
        boxShadow: "none",
        borderRadius: 0,
        padding: "1px",
    },
    normalDayPaper: {
        width: "35px",
        height: "35px",
        backgroundColor: "#e8f5e9",
        margin: "3px",
        boxShadow: "none",
        borderRadius: 0,
        padding: "1px",
        cursor: "pointer",
    },
    selectedDayPaper: {
        width: "31px",
        height: "31px",
        backgroundColor: "#f9fbe7",
        margin: "3px",
        boxShadow: "none",
        borderRadius: 0,
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: "lime",
        padding: "1px",
        cursor: "pointer",
    },
    todayPaper: {
        width: "35px",
        height: "35px",
        backgroundColor: "lightGreen",
        margin: "3px",
        boxShadow: "none",
        borderRadius: 0,
        padding: "1px",
        cursor: "pointer",
        color: " white",
    }
}))


const materialTheme = createMuiTheme({
    
    overrides: {
        //@ts-ignore
        MuiPickersBasePicker:{
            pickerView:{
                backgroundColor:"white",
                maxWidth: "280px",
                minWidth: "250px",
            }
        },
        MuiPickersStaticWrapper: {
            staticWrapperRoot: {
                minWidth: "250px",
            }
            
        },
        //@ts-ignore
        MuiPickersCalendarHeader: {
            dayLabel: {
                width: "25px"
            }
        },

        //@ts-ignore
        MuiPickersDay: {
            day: {
                color: "black",
                fontFamily: "\"Do Hyeon\", sans-serif",
                backgroundColor: "white",
                borderRadius:"0px",
                width: "25px",
                height: "25px"
            },
            container:{
                backgroundColor:"white"
            },
            daySelected: {
                backgroundColor: "#329AD0",
                color:"#fff",
                borderRadius: "50%"
            },
            dayDisabled: {
                color: "lightgray",
            },
            current: {
                color: "#2cd8ff",
            },
        },
    },

});

export default BookingDate;