import { Route, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
    bookingType: string;
}

function PrivateRoute(props: Props) {
    //const { bookingCustomer, bookingDate, bookingService } = useBookingContext();
   // console.log(props, bookingCustomer, bookingDate, bookingService)
    // if (props.bookingType === "date") {
    //     if (!bookingService) {
    //         return <Redirect to="/" />
    //     }
    // }
    // else if (props.bookingType === "customer") {
    //     if (!bookingDate) {
    //         return <Redirect to="/" />
    //     }
    // }
    // else if (props.bookingType === "confirm") {
    //     if (!bookingService || !bookingDate || !bookingCustomer) {
    //         return <Redirect to="/" />
    //     }
    // }
    return <Route {...props} />
}

export default PrivateRoute;