import { Box, ListItemIcon, makeStyles, MenuItem, MenuList, Typography, useMediaQuery } from '@material-ui/core'
import { EventAvailable, Info } from "@material-ui/icons/";
import { Link } from 'react-router-dom';
import LogoPlaceHolder from "../../images/logo-placeholder.png";

function Menu() {
    const tabletView = useMediaQuery('(max-width:1300px)');
    const classes = useStyles();

    return (
        <Box boxShadow={!tabletView ? 3 : 0}  >
            <img src={LogoPlaceHolder} alt="logo place holder" width="100%" />
            <Box>
                <MenuList className={classes.menu}>

                    <Link to="/">
                        <MenuItem className={classes.link} >
                            <ListItemIcon>
                                <EventAvailable />
                            </ListItemIcon>
                            <Typography variant="inherit">Booking</Typography>
                        </MenuItem>
                    </Link>
                    <Link to="/">
                        <MenuItem className={classes.link} >
                            <ListItemIcon>
                                <EventAvailable />
                            </ListItemIcon>
                            <Typography variant="inherit">Services</Typography>
                        </MenuItem>
                    </Link>
                    <Link to="/company/about">
                        <MenuItem className={classes.link}>
                            <ListItemIcon>
                                <Info />
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                                About
                        </Typography>
                        </MenuItem>
                    </Link>
                </MenuList>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    link: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    menu: {
        padding: 0
    }
}))

export default Menu;