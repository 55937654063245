import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography, ListItemIcon } from '@material-ui/core';
import { useHistory, useParams } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { useMediaQuery, Radio } from '@material-ui/core/';
import { useBookingContext } from '../state/booking-context';
import { Service } from '../models/staff';
import Booking from "../components/booking/booking";
import { useCallback, useEffect, useState } from 'react';
import { getServices } from '../api/appointment';

type TParams = { staff: string };
function BookingService () {
    const mobileView = useMediaQuery('(max-width:600px)');
    const classes = useStyles();
    const history = useHistory();
    const params = useParams<TParams>();
    const { bookingService, setBookingService, staffName, staff } = useBookingContext();
    const [storeServices, setStoreServices] =useState<Service[]>();

    const getStoreServices = useCallback(async ()=> {
        try {
            const subdomain = window.location.hostname.split('.')[0];
            const staffName = params.staff;
            const services = await getServices(subdomain as string, staffName);
            setStoreServices(services);
        } catch (err) {
            console.error(err);
        }
    }, [params])

    useEffect(() => {
        getStoreServices();
    }, [getStoreServices])
    function handleChooseService(service: Service) {
        setBookingService(service);
        if((staffName|| params.staff) && staff) {
            history.push("/booking/date");
        } else {
            history.push("/booking/staff");
        }
    }

    return (
        <Booking>
            <Box display="flex" justifyContent="center" marginBottom="20px">
                <Typography style={{ fontSize: 15, fontWeight:600 }}>Choose Service</Typography>
            </Box>
            
            <Box padding={!mobileView ? 3 : 0}>
                {
                    !mobileView ? <Typography variant="h6">All Services</Typography> : <Typography >All Services</Typography>
                }
                {
                    storeServices && (
                        <List >
                            {storeServices.map(service => (
                                <ListItem key={service.id} className={classes.listItem} onClick={() => handleChooseService(service)} >
                                    <ListItemIcon>
                                        <Radio color="primary" checked={bookingService && bookingService.id === service.id} />
                                    </ListItemIcon>
                                    <ListItemAvatar>
                                        <Avatar >
                                            {service.serviceDuration}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText color="textSecondary" primary={service.name} secondary={<CurrencyFormat value={service.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />} />
                                </ListItem>
                            ))}
                        </List>
                    )
                }
            </Box>
        </Booking>
    )
}

const useStyles = makeStyles(theme => ({
    listItem: {
        padding: "0",
        borderBottom: "1px solid #BDBDBD",
        color: "#BDBDBD",
        cursor: "pointer"
    }
}))

export default BookingService;
