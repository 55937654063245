import { Box, makeStyles, useMediaQuery } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useBookingContext } from "../../state/booking-context";

function Booking({ children }: any) {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const [activeStep, setActiveStep] = useState(0);
    const [isButtonClick, setButtonClick] = useState(false);
    const [completed, setCompleted] = useState(new Set());
    const mobileView = useMediaQuery('(max-width:600px)');
    const { bookingCustomer, staff, bookingDate, bookingService, appointmentBooked, staffName } = useBookingContext();

    const getSteps = useCallback(() => {
        if(!staffName)
            return ['Services', 'Staff', 'Date', 'Your Info', 'Confirm'];
            
        return ['Services', 'Date', 'Your Info', 'Confirm'];
    },[staffName])

    const totalSteps = useCallback(() => {
        return getSteps().length;
    }, [getSteps]);

    const completedSteps = useCallback(() => {
        return completed.size;
    }, [completed]);

    const allStepsCompleted = useCallback(() => {
        return completedSteps() === totalSteps();
    },[completedSteps, totalSteps]);

    const isLastStep = useCallback(() => {
        return activeStep === totalSteps() - 1;
    }, [totalSteps, activeStep]);

    const handleNext = useCallback(() => {
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed
              // find the first step that has been completed
              getSteps().findIndex((step, i) => !completed.has(i))
            : activeStep + 1;
    
        setActiveStep(newActiveStep);
    }, [activeStep, allStepsCompleted, completed, getSteps, isLastStep]);
    
    const handleStep = (step:number) => () => {
        setButtonClick(true);
        
        const steps = getSteps();
        switch(steps[step]) {
            case 'Services': 
                setActiveStep(step);
                history.push("/booking/service")
                break;
            case 'Staff':
                if(!bookingService) return;
                setActiveStep(step);
                history.push('/booking/staff');
                break;
            case 'Date':
                if(!staff) return;
                setActiveStep(step);
                history.push('/booking/date');
                break;
            case 'Your Info':
                if(!bookingDate) return;
                setActiveStep(step);
                history.push('/booking/customer');
                break;
            case 'Confirm':
                if(!bookingCustomer) return;
                setActiveStep(step);
                history.push('/booking/confirm');
                break;
            default:
                history.push('/');
                break;
        }
    };
    
    const handleComplete = useCallback(() => {
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);
        setCompleted(newCompleted);
        if (completed.size !== totalSteps()) {
          handleNext();
        }
    },[activeStep, completed, handleNext, totalSteps]);

    function isStepComplete(step:any) {
        return completed.has(step);
    }

    useEffect(() => {

        switch(location.pathname) {
            case '/booking/service':
                setActiveStep(0);
                break;
            case '/booking/staff': 
                setActiveStep(1);
                break;
            case '/booking/date':
                setActiveStep(staffName ? 1 : 2);
                break;
            case '/booking/customer':
                setActiveStep(staffName ? 2 : 3);
                break
            case '/booking/confirm':
                setActiveStep(staffName ? 3 : 4);
                break
            default :
                setActiveStep(0);
                break;
        }
        // if(isButtonClick) return;
        // if(staffName) {
        //     if (bookingService) setActiveStep(1);
        //     if (bookingDate) setActiveStep(2);
        //     if (bookingCustomer) setActiveStep(3);
        //     if (appointmentBooked) handleComplete();
        // } else {
        //     if (bookingService) setActiveStep(1);
        //     if (staff) setActiveStep(2);
        //     if (bookingDate) setActiveStep(3);
        //     if (bookingCustomer) setActiveStep(4);
        //     if (appointmentBooked) { handleComplete() }
        // }
    }, [setActiveStep, isButtonClick, appointmentBooked, bookingCustomer, bookingDate, bookingService, handleComplete, staff, staffName])

    return (
        <div>
            { !mobileView &&
        <Stepper alternativeLabel nonLinear activeStep={activeStep} classes={classes}>
        {getSteps().map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
        </Stepper>
        }
        <Box padding={!mobileView ? 2 : "5px"} mb={2} >
                {children}
        </Box>
        </div>
    )

    // function getProgress() {
    //     let progress = 0;
    //     if(staffName) {
    //         if (bookingService) progress = 25;
    //         if (bookingDate) progress = 50;
    //         if (bookingCustomer) progress = 75;
    //         if (appointmentBooked) progress = 100;
    //     } else {
    //         if (bookingService) progress = 20;
    //         if (staff) progress = 40;
    //         if (bookingDate) progress = 60;
    //         if (bookingCustomer) progress = 80;
    //         if (appointmentBooked) progress = 100;
    //     }
        
    //     return progress;
    // }

    // return !mobileView ?
    //     (<Box padding={!mobileView ? 4 : 0}>
    //         <Box marginY={2}>
    //             <Grid container spacing={2} justify="space-between">
    //                 <Grid item lg={!staffName ? 2 : 3}>
    //                     <Link to="/booking/service">
    //                         <Box fontWeight={bookingService ? '500' : '400'}>
    //                         <Typography className={classes.label}  variant="body2" color="textSecondary">
    //                            {
    //                                bookingService ?  bookingService.serviceDuration + 'MIN' : 'Service'
    //                            } 
    //                         </Typography>
    //                         </Box>
                            
    //                     </Link>
    //                 </Grid>
    //                 {  !staffName && 
    //                 <Grid item lg={!staffName ? 2 : 3}>
    //                     <Link to="/booking/staff">
    //                         <Typography className={classes.label} variant="body2" color="textSecondary">
    //                             {staff ?  staff.name : 'Staff'}
    //                         </Typography>
    //                     </Link>
    //                 </Grid> 
    //                 }
    //                 <Grid item lg={!staffName ? 2 : 3} >
    //                     <Link to="/booking/date" className={`${!bookingService ? classes.disabledLink : ""}`} >
    //                         <Typography className={classes.label} variant="body2" color="textSecondary">
    //                             {bookingDate ? bookingDate.format('hh:mm a') : 'Date'}
    //                         </Typography>
    //                     </Link>
    //                 </Grid>
    //                 <Grid item lg={!staffName ? 2 : 3}>
    //                     <Link to="/booking/customer" className={`${!bookingDate ? classes.disabledLink : ""}`}>
    //                         <Typography className={classes.label} variant="body2" color="textSecondary">Your Info</Typography>
    //                     </Link>
    //                 </Grid>
    //                 <Grid item lg={!staffName ? 2 : 3} >
    //                     <Link to="/booking/confirm" className={`${!bookingService || !bookingCustomer || !bookingDate ? classes.disabledLink : ""}`}>
    //                         <Typography className={classes.label} variant="body2" color="textSecondary">Confirm</Typography>
    //                     </Link>
    //                 </Grid>
    //             </Grid>
    //         </Box>
    //         <LinearProgress variant="determinate" value={getProgress()} />
    //         <Box padding={!mobileView ? 2 : "5px"} mb={2} >
    //             {children}
    //         </Box>
    //     </Box >
    //     ) : (
    //         <Box padding={!mobileView ? 2 : "5px"} mb={2} >
    //         {children}
    //     </Box>
    //     )  
        

}


const useStyles = makeStyles(() => ({
    root: {
        padding: '0'
    },
    // label: {
    //     fontSize: "12px"
    // },
    // disabledLink: {
    //     pointerEvents: "none"
    // },
    // arrow: {
    //     float: "left",
    //     position: "absolute",
    //     left: "20px",
    //     lineHeight: "22px",
    //     fontSize: "22px",
    //     marginRight: "10px",
    //     cursor: "pointer"
    // }
}))

export default Booking;
