import { useMediaQuery, Radio } from '@material-ui/core/';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography, ListItemIcon } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { useBookingContext } from '../state/booking-context';
import { Staff } from '../models/staff';
import Booking from "../components/booking/booking";
import { useEffect } from 'react';


function BookingStaff() {
    const mobileView = useMediaQuery('(max-width:600px)');
    const { bookingService, setStaff } = useBookingContext();
    const history = useHistory();
    const classes = useStyles();
    function handleSelectStaff(staff: Staff) {
        setStaff(staff);
        history.push("/booking/date");
    }

    function goBack() {
        history.push("/booking/service");
    }
    useEffect(() => {
        if(!bookingService) {
            history.push("/booking/service");
        }
    },[bookingService, history]);

    return (
        <Booking>
            <Box display="flex" justifyContent="center" marginBottom="20px">
            {mobileView && 
                <ArrowBackIosIcon color="primary" className={classes.arrow} onClick={() => goBack()} />
            }
                <Typography style={{ fontSize: 15, fontWeight:600 }}>Choose Provider</Typography>
            </Box>
            <Box padding={!mobileView ? 3 : 0}>
                {
                    bookingService && (
                        <List >
                            {bookingService.staffs.map(staff => (
                                <ListItem key={staff.id} className={classes.listItem} onClick={() => handleSelectStaff(staff)} >
                                    <ListItemIcon>
                                        <Radio color="primary" checked={bookingService && bookingService.id === staff.id} />
                                    </ListItemIcon>
                                    <ListItemAvatar>
                                        <Avatar >
                                            {staff.avatar}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText color="textSecondary" primary={staff.name} secondary={staff.description} />
                                </ListItem>
                            ))}
                        </List>
                    )
                }
            </Box>
        </Booking>
    )
}

const useStyles = makeStyles(theme => ({
    listItem: {
        padding: "0",
        minHeight: "57px",
        borderBottom: "1px solid #BDBDBD",
        color: "#BDBDBD",
        cursor: "pointer"
    },
    arrow: {
        float: "left",
        position: "absolute",
        left: "20px",
        lineHeight: "22px",
        fontSize: "22px",
        marginRight: "10px",
        cursor: "pointer"
    }
}))

export default BookingStaff;


