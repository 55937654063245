import { Box, Typography, Divider, Button, FormControl, TextField, InputAdornment, CircularProgress, IconButton, makeStyles, useMediaQuery } from "@material-ui/core";
import { Comment } from "@material-ui/icons";
import { useState } from "react";
import Booking from "../components/booking/booking";
import CurrencyFormat from "react-currency-format";
import CheckIcon from '@material-ui/icons/Check';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useBookingContext } from "../state/booking-context";
import { BookingAppointment } from "../models/booking";
import { bookNewAppointment } from "../api/appointment";
import { useHistory } from "react-router";


function BookingConfirm() {
    const { bookingCustomer, bookingDate, bookingService, staff, appointmentBooked, setAppointmentBooked, store } = useBookingContext();
    const [note, setNote] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const classes = useStyles();
    const history = useHistory();
    const mobileView = useMediaQuery('(max-width:600px)');
    async function handleBookAppointment() {
        if (bookingCustomer && bookingDate && bookingService && staff && store) {
            setLoading(true);
            const bookingAppointment: BookingAppointment = {
                date: bookingDate,
                service: bookingService,
                staff: staff,
                note: note || "",
                store: store,
                customerId: bookingCustomer?.id,
                customer: bookingCustomer,
            }
            try {
                const data = await bookNewAppointment(bookingAppointment);
                if (data) {
                    setAppointmentBooked(true);
                }
            } catch (err) {
                alert("We are having problem creating your appointment, please try again.")
                console.log(err);
            } finally {
                setLoading(false);
            };
        }

    }
    function goBack() {
        history.push("/booking/customer");
    }

    return (
        <Booking>
            <Box my={1} width="100%">
                <Box display="flex" justifyContent="center" marginBottom="20px">
                { mobileView &&
                    <ArrowBackIosIcon color="primary" className={classes.arrow} onClick={() => goBack()} />
                }
                    <Typography style={{ fontSize: 15, fontWeight:600 }}>Confirm Your Appointment</Typography>
                </Box>
                <Box my={2} padding={2} width="100%" boxShadow={2}>
                    <Box>
                        <Box display="flex" my={2} textAlign="end" justifyContent="space-between" >
                            <Typography color="textSecondary">Date:</Typography>
                            <Box>
                                <Typography>{bookingDate?.format("dddd, MMMM Do YYYY, h:mm a")}</Typography>
                            </Box>
                        </Box>

                        <Box display="flex" my={2} textAlign="end" justifyContent="space-between" >
                            <Typography color="textSecondary">Service:</Typography>
                            <Box>
                                <Typography>{bookingService?.name}</Typography>
                            </Box>
                        </Box>

                        <Box display="flex" my={2} textAlign="end" justifyContent="space-between" >
                            <Typography color="textSecondary">Price:</Typography>
                            <Box>
                                <Typography><CurrencyFormat value={bookingService?.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Typography>
                            </Box>
                        </Box>

                        <Box display="flex" my={2} textAlign="end" justifyContent="space-between" >
                            <Typography color="textSecondary">Duration:</Typography>
                            <Box>
                                <Typography>{bookingService?.serviceDuration} minutes</Typography>
                            </Box>
                        </Box>

                        <Box display="flex" my={2} textAlign="end" justifyContent="space-between" >
                            <Typography color="textSecondary">Staff:</Typography>
                            <Box>
                                <Typography>{staff?.name}</Typography>
                            </Box>
                        </Box>

                        <Box display="flex" my={2} textAlign="end" justifyContent="space-between" >
                            <Typography color="textSecondary">Your Info:</Typography>
                            <Box>
                                <Typography>{bookingCustomer?.firstName} {bookingCustomer?.lastName}</Typography>
                                <Typography>{bookingCustomer?.countryCode} {bookingCustomer?.phoneNumber}</Typography>
                                <Typography>{bookingCustomer?.email}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Divider />
                    <Box p={1}></Box>
                    {loading && (
                        <Box display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {
                        !loading && appointmentBooked && (
                            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" textAlign="center">
                                <IconButton color="secondary"  >
                                    <CheckIcon fontSize="large" />
                                </IconButton>
                                <Typography color="secondary">Your Appointment Has Been Successfully Booked, Please Check Your Email For The Exact Confirmation</Typography>
                            </Box>
                        )
                    }

                    {
                        !loading && !appointmentBooked && (
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" my={2}>
                                <FormControl fullWidth>
                                    <TextField
                                        multiline
                                        rows={2}
                                        rowsMax={4}
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label="Note For Appointment (Optional)"
                                        defaultValue=""
                                        size="medium"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment style={{ marginRight: 10, paddingBottom: 10 }} position="start" >
                                                    <Comment />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                                <Box p={1}></Box>
                                <Button size="large" variant="contained" color="primary" onClick={handleBookAppointment} >Book Appointment</Button>
                            </Box>
                        )
                    }
                </Box>
                <Box boxShadow={2} p={2} mt={3} textAlign="start" >
                    <Typography variant="body2" color="textSecondary">
                        When booking with {staff?.name} you may receive appointment specific communication from {store?.name} . This may include booking and cancellation confirmations, payment receipts and appointment reminders via email or SMS.
                    </Typography>
                </Box>
            </Box>
        </Booking>
    )
}

const useStyles = makeStyles(theme => ({
    arrow: {
        float: "left",
        position: "absolute",
        left: "20px",
        lineHeight: "22px",
        fontSize: "22px",
        marginRight: "10px",
        cursor: "pointer"
    }
}))

export default BookingConfirm;