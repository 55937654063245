import React, { useEffect, useState } from 'react'
import { Box, TextField, FormControl, Button, InputAdornment, useMediaQuery, Typography, Container, makeStyles } from '@material-ui/core';
import { AccountCircle, Email } from '@material-ui/icons';
import { useHistory } from 'react-router';
import phone from 'phone';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { validatePhoneNumber, validateEmail } from "../utils/validate";
import { Customer, DEFAULT_CUSTOMER } from '../models/customer';
import { useBookingContext } from '../state/booking-context';
import { getCustomerByPhoneNumber } from '../api/appointment';
import Booking from "../components/booking/booking";
import BookingPhoneNumber from '../components/booking/booking-phone-number';

function BookingCustomer() {
    const { bookingCustomer, setBookingCustomer } = useBookingContext();
    const [firstNameErr, setFirstNameErr] = useState<string>();
    const [lastNameErr, setlastNameErr] = useState<string>();
    const [emailErr, setEmailErr] = useState<string>();
    const [phoneNumberErr, setPhoneNumberErr] = useState<string>();
    const [customer, setCustomer] = useState<Customer>(DEFAULT_CUSTOMER);
    const [showCustomerForm, setShowCustomerForm] = useState<boolean>();
    const tabletView = useMediaQuery('(max-width:1280px)');
    const mobileView = useMediaQuery('(max-width:600px)');
    const history = useHistory();
    const classes = useStyles()
    useEffect(() => {
        if (bookingCustomer) {
            setCustomer(bookingCustomer);
            setShowCustomerForm(true);
        }
    }, [bookingCustomer]);

    useEffect(() => {
        if (customer.phoneNumber) {
            getCustomerByPhoneNumber(customer.phoneNumber).then(customer => setCustomer(customer))
                .catch(err => {
                    console.log(err);

                }).finally(() => setShowCustomerForm(true));
        }
    }, [customer.phoneNumber]);

    function validateForm(): boolean {
        let validForm = true;
        if (!customer.firstName) {
            setFirstNameErr("First Name Must Not Be Empty");
            validForm = false;
        } else {
            setFirstNameErr(undefined);
        }

        if (!customer.lastName) {
            setlastNameErr("Last Name Must Not Be Empty");
            validForm = false;
        } else {
            setlastNameErr(undefined);
        }

        if (!validateEmail(customer.email)) {
            setEmailErr("Email Is Not Valid");
            validForm = false;
        } else {
            setEmailErr(undefined);
        }

        if (!validatePhoneNumber(customer.phoneNumber)) {
            setPhoneNumberErr("Phone Number Is Not Valid");
            validForm = false;
        } else {
            setPhoneNumberErr(undefined);
        }

        return validForm;
    }

    function handleSubmitCustomerInfo(): boolean {
        const validForm = validateForm();
        if (!validForm) return false;
        console.log(customer);
        setBookingCustomer(customer);
        history.push("/booking/confirm");
        return true;
    }

    function handlePhoneChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) {
        let phoneInput: string;
        if (typeof e === "string") {
            phoneInput = e;
        }
        else {
            phoneInput = e.target.value;
        }
        const [customerPhoneNumber] = phone(phoneInput);
        const phoneNumber = customerPhoneNumber ? customerPhoneNumber.substr(2) : "";
        const countryCode = customerPhoneNumber ? customerPhoneNumber.substr(0, 2) : ""
        setCustomer({ ...customer, phoneNumber, countryCode })
    }

    function handleFirstNameChange(firstName: string) {
        setCustomer({ ...customer, firstName: firstName });
    }

    function handleLastNameChange(lastName: string) {
        setCustomer({ ...customer, lastName });
    }

    function handleEmailChange(email: string) {
        setCustomer({ ...customer, email })
    }

    function goBack() {
        history.push("/booking/date");
    }

    return (
        <Booking>
            <Box my={1}>
                {showCustomerForm && (

                    <Container>
                        <Box display="flex" justifyContent="center" marginBottom="20px">
                        { mobileView &&
                            <ArrowBackIosIcon color="primary" className={classes.arrow} onClick={() => goBack()} />
                        }
                            <Typography style={{ fontSize: 15, fontWeight:600 }}>Enter Your Info</Typography>
                        </Box>
                    <Box marginBottom="36px">
                        <FormControl fullWidth>
                            <Box display="flex" flexDirection={!tabletView ? "row" : "column"} justifyContent="space-between">
                                <Box>
                                    <TextField
                                        helperText={firstNameErr}
                                        error={!!firstNameErr}
                                        defaultValue={customer.firstName}
                                        required
                                        autoFocus
                                        onChange={(e) => handleFirstNameChange(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        id="standard-error-helper-text"
                                        label="First Name"
                                        size="medium"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircle />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                                {!tabletView ? <Box ></Box> : <Box py={2}></Box>}
                                <Box >
                                    <TextField
                                        helperText={lastNameErr}
                                        error={!!lastNameErr}
                                        required
                                        defaultValue={customer.lastName}
                                        onChange={(e) => handleLastNameChange(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label="Last Name"
                                        size="medium"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircle />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                            </Box>
                        </FormControl>
                        <Box py={2}></Box>
                        <FormControl fullWidth>
                            <TextField
                                helperText={emailErr}
                                error={!!emailErr}
                                defaultValue={customer.email}
                                required
                                onChange={(e) => handleEmailChange(e.target.value)}
                                type="email"
                                variant="outlined"
                                fullWidth
                                label="Email"
                                size="medium"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Email />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>
                    </Box>

                    </Container>
                    
                )}
                {/* <Box py={2}></Box> */}
                {!showCustomerForm && <Box display="flex" justifyContent="center" marginBottom="20px">
                { (mobileView ) &&
                    <ArrowBackIosIcon color="primary" className={classes.arrow} onClick={() => goBack()} /> }
                    <Typography style={{ fontSize: 15, fontWeight:600 }}>Enter Phone Number</Typography>
                </Box>}
                <Box px={2}>
                    <BookingPhoneNumber phoneNumber={customer.phoneNumber} phoneNumberErr={phoneNumberErr} handlePhoneChange={handlePhoneChange} />
                
                </Box>
                <Box py={2}></Box>
                {
                    showCustomerForm && (
                        <Box py={3} display="flex" justifyContent="flex-end">
                            <Button disabled={!customer} type="button" onClick={handleSubmitCustomerInfo} size="large" variant="contained" color="primary" >Next</Button>
                        </Box>
                    )
                }
            </Box>
        </Booking>
    )
}
const useStyles = makeStyles(theme => ({
    arrow: {
        float: "left",
        position: "absolute",
        left: "20px",
        lineHeight: "22px",
        fontSize: "22px",
        marginRight: "10px",
        cursor: "pointer"
    }
}))

export default BookingCustomer;