import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";

interface Props {
    open: boolean;
    noteForCustomer: string | undefined;
    storeName: string | undefined;
    handleClose: () => void;
}
export default function NoteDialog({ open, handleClose, noteForCustomer, storeName }: Props) {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{storeName} Note</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {noteForCustomer}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="contained">
                    Continute
                </Button>
            </DialogActions>
        </Dialog>
    )
}
