import { Box, Divider, Typography, useMediaQuery } from '@material-ui/core';
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from 'moment-timezone';

import { getStaff, getStore } from '../../api/appointment';
import { useBookingContext } from "../../state/booking-context";
import { getMapSnapShot } from "../../utils/map-snapshot";
import NoteDialog from "./note-dialog";
import { OpenHour } from '../../models/store';

export default function GeneralInfo() {
    const history = useHistory();
    const [showNoteDialog, setShowNoteDialog] = useState<boolean>(false);
    const [openHours, setOpenHours] = useState<OpenHour[]>([]);
    const { staff, setStaff, setStore, store, setStaffName, setSubdomain, staffName } = useBookingContext();
    const mobileView = useMediaQuery('(max-width:600px)');
    const getStoreInfo = useCallback(async (storeLink: string) => {
        try {
            const storeData = await getStore(storeLink);
            document.title = "Uzmos | " + storeData.name;
            setStore(storeData);
            let storeOpenHours: OpenHour[] = [];
            for(let i = 0; i < storeData.openHours.length; i++) {
                const pointer = (i + storeData.appointmentSetting.weekStartDay) % storeData.openHours.length;
                storeOpenHours.push(storeData.openHours[pointer])
            }
            setOpenHours(storeOpenHours);
           
            if (storeData.appointmentSetting.noteForCustomer) {
                setShowNoteDialog(true);
            }
        } catch (err) {
            if (!err.response) {
                console.error(err);
            }
            else {
                const { data } = err.response;
                return history.push(`/not-found?message=${data.message}&status=${data.status}`);
            }
        }
    }, [setStore, history])

    const getStaffInfo = useCallback(async (storeLink: string, staffName: string) => {
        try {
            getStaff(storeLink, staffName).then(data => {
                setStaffName(staffName);
                setStaff(data);
            }).catch(err=> {
                history.push( '/')
            });
            
        } catch (err) {
            console.error(err);
        }

    }, [setStaff,setStaffName, history])


    useEffect(() => {
        //var domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
        const  subdomain = window.location.hostname.split('.')[0];
        const pathName = window.location.pathname.split('/')[1];
  
        const _staffName = pathName;
    
        // if(_staffName) {
       
        //     setStaffName(_staffName);
        // }
        const storeLink = (subdomain !== 'localhost' || 'uzmos.com') ? subdomain : 'corgistickers';
        setSubdomain(storeLink)
        getStoreInfo(storeLink);
        if (_staffName) {
            getStaffInfo(storeLink, _staffName);
        } 

    }, [setSubdomain, getStaffInfo, getStoreInfo]);

    function handleCloseNoteDialog() {
        setShowNoteDialog(false);
    }

    function getDate(day: number): string {
        switch (day) {
            case 0: {
                return "Sunday";
            }
            case 1: {
                return "Monday";
            }
            case 2: {
                return "Tuesday";
            }
            case 3: {
                return "Wednesday";
            }
            case 4: {
                return "Thursday";
            }
            case 5: {
                return "Friday";
            }
            case 6: {
                return "Saturday";
            }
            default: {
                return ""
            }
        }
    }

    return (
        <Box padding={!mobileView ? 3 : 1}>
            <NoteDialog open={showNoteDialog} handleClose={handleCloseNoteDialog} noteForCustomer={store?.appointmentSetting.noteForCustomer} storeName={store?.name} />
            <Box >
                <Typography>Location</Typography>
                <Box marginY={1}>
                    <img width="100%" src={getMapSnapShot(store?.latitude, store?.longitude)} alt="location" />
                </Box>
            </Box>
            <Box marginY={2}>
                <Divider />
            </Box>

            <Box>
                {
                    store?.storeSetting.timeZone && (
                        <Typography>Work Hours ({moment.tz(store.storeSetting.timeZone).zoneAbbr()})</Typography>
                    )
                }

                <Box>
                    {
                        (staffName ? staff?.workingHours : openHours )?.map((item:any) => (
                            <Box display="flex" justifyContent="space-between" key={item.id} >
                                <Typography style={{ width: "20px!important" }} variant="body2">{getDate(item.day)}</Typography>
                                <Typography variant="body2">
                                    {item.fromHour} - {item.toHour}
                                </Typography>
                            </Box>
                        ))
                    }
                </Box>

            </Box>
        </Box>
    )
}
